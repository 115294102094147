import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';

import { Container, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Header } from 'components';

import { useAuth, usePage, useTheme } from 'hooks';

import {
  ErroValidaLink,
  ErroExpiradoOuPreenchido,
  ValidacaoCPF,
  VisualizarDocumento,
} from 'pages';
import { DadosPreenchimento } from 'pages/VisualizarDocumento';

import { api } from 'services';

const useStyles = (): any => ({
  container: {
    marginTop: 5,
    padding: 10,
  },
  containerXs: {
    padding: 2,
  },
});

const RoutesGerenciamento: React.FC = () => {
  const { currentPage, setCurrentPage } = usePage();
  const { isAuthenticated, session, setSession } = useAuth();
  const { isLoaded, setLayout, setShowLoading } = useTheme();
  const classes = useStyles();
  const matchesXs = useMediaQuery('(min-width: 465px)');
  const location = useLocation();

  const [dadosPreenchimento, setDadosPreenchimento] =
    useState<DadosPreenchimento>({} as DadosPreenchimento);

  const link = useMemo(() => location.pathname.split('/').pop(), [location]);

  const linkValidation = async (): Promise<void> => {
    const url = `pdf-declaracao-saude/dados-preenchimento-formulario/${link}`;
    setShowLoading(true);

    await api
      .get(url)
      .then(({ data }) => {
        const { protocolo } = data;

        setDadosPreenchimento(data);
        setSession(prevState => ({
          ...prevState,
          protocol: protocolo,
        }));
        setCurrentPage(0);
      })
      .catch(err => {
        if (!err.response) {
          toast.error(
            'Problemas ao conectar no servidor! Tente novamente mais tarde.',
            { duration: 15000 },
          );
          return;
        }
        if (err.response.status === 404) {
          toast.error('Link não encontrado!');
        }

        setCurrentPage(-2); // Redirect to error page
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    linkValidation();
  }, []);

  const loadAssets = async (protocolo: string): Promise<void> => {
    setShowLoading(true);

    await api
      .get(`parametrizacao-layout/protocolo/${protocolo}`)
      .then(({ data }) => {
        setLayout(data.layout);
      })
      .catch(() => {
        toast.error('Não foi possível carregar os assets');
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    const { protocol } = session;

    if (protocol) {
      loadAssets(protocol);
    }
  }, [session.protocol]);

  return (
    <>
      {isLoaded && <Header />}
      <Container sx={matchesXs ? classes.container : classes.containerXs}>
        {currentPage === -2 && !isAuthenticated && <ErroValidaLink />}
        {currentPage === -1 && !isAuthenticated && <ErroExpiradoOuPreenchido />}
        {currentPage === 0 && isLoaded && !isAuthenticated && <ValidacaoCPF />}
        {currentPage === 0 && isAuthenticated && (
          <VisualizarDocumento {...dadosPreenchimento} />
        )}
      </Container>
    </>
  );
};

export default RoutesGerenciamento;
