import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Box, Button, Icon, Typography, useMediaQuery } from '@mui/material';

import { mapValues } from 'lodash';

import { QuestionAccordion } from 'components';

import { IBeneficiario, IEspecificacao } from 'context';

import { useQuestion, usePage, useFormData } from 'hooks';

const useStyles = (): any => ({
  page: {
    paddingTop: 5,
  },
  pageTitle: {
    marginBottom: 5,
    '& > h6': { maxWidth: 800, width: '100%', margin: '0 auto' },
  },
  pageTitleXs: {
    marginBottom: 2,
    '& > h6': { maxWidth: 800, width: '100%', margin: '0 auto' },
  },
  titleXs: {
    marginTop: '35px',
    fontSize: '1.7rem',
  },
  subTitle: {
    paddingTop: '20px',
  },
  subTitleXs: {
    paddingTop: '15px',
    fontSize: '1rem',
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: 2,
    justifyItems: 'center',
  },
  wrapperXs: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
    justifyItems: 'center',
  },
  questionAccordion: {
    maxWidth: 850,
    width: '100%',
  },
  questionAccordionSm: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  nav: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr',
    gridTemplateRows: '1fr',
    maxWidth: 650,
    width: '100%',
    height: 'fit-content',
    margin: '30px auto 0',
    alignItems: 'center',
  },
  navSm: {
    display: 'flex',
    margin: '30px auto 0',
    marginBottom: '10px',
    justifyContent: 'space-between',
    maxWidth: 650,
    paddingBottom: '10px',
  },
  pageNumber: {
    fontSize: '1rem',
    alignSelf: 'center',
    margin: 'auto',
  },
  button: {
    width: '110px',
  },
  fowardButton: {
    width: '110px',
    justifySelf: 'end',
  },
});

interface IChoices {
  bloqueado?: boolean;
  hasDisease: string | null;
  especificacao: IEspecificacao[];
  especificacaoManual: string;
  especificacaoManualAlteracoes: string;
}

interface IFormValues {
  [key: string]: IChoices;
}

interface IPastFormValues {
  [key: string]: {
    illness?: boolean;
  };
}

export const MIN_LENGTH_INPUT_TEXT = 3;
export const MAX_LENGTH_INPUT_TEXT = 100;
export const MAX_LENGTH_INPUT_TEXT_TEMPO = 50;

const yupSchemaTextField = (
  condition: boolean,
  min: number,
  max: number,
): any =>
  condition
    ? yup
        .string()
        .min(min, `O tamanho mínimo é de ${min} caracteres`)
        .max(max, `O tamanho máximo é de ${max} caracteres`)
    : yup.string().notRequired();

const schema = yup.lazy(obj =>
  yup
    .object(
      mapValues(obj, () =>
        yup.object().shape(
          {
            hasDisease: yup.boolean().required('Este campo é obrigatório'),
            especificacao: yup.array().of(
              yup.object().shape(
                {
                  portador: yup.boolean(),
                  titulo: yup
                    .string()
                    .when('portador', portador =>
                      yupSchemaTextField(
                        portador,
                        MIN_LENGTH_INPUT_TEXT,
                        MAX_LENGTH_INPUT_TEXT,
                      ),
                    ),
                  grau: yup
                    .string()
                    .when(['portador', 'grau'], (portador, val) =>
                      yupSchemaTextField(
                        portador && val?.length > 0,
                        MIN_LENGTH_INPUT_TEXT,
                        MAX_LENGTH_INPUT_TEXT,
                      ),
                    ),
                  local: yup
                    .string()
                    .when(['portador', 'local'], (portador, val) =>
                      yupSchemaTextField(
                        portador && val?.length > 0,
                        MIN_LENGTH_INPUT_TEXT,
                        MAX_LENGTH_INPUT_TEXT,
                      ),
                    ),
                  tempo: yup
                    .string()
                    .when(['portador', 'tempo'], (portador, val) =>
                      yupSchemaTextField(
                        portador && val?.length > 0,
                        MIN_LENGTH_INPUT_TEXT,
                        MAX_LENGTH_INPUT_TEXT_TEMPO,
                      ),
                    ),
                  tipo: yup
                    .string()
                    .when(['portador', 'tipo'], (portador, val) =>
                      yupSchemaTextField(
                        portador && val?.length > 0,
                        MIN_LENGTH_INPUT_TEXT,
                        MAX_LENGTH_INPUT_TEXT,
                      ),
                    ),
                },
                [
                  ['grau', 'grau'],
                  ['local', 'local'],
                  ['tempo', 'tempo'],
                  ['tipo', 'tipo'],
                ],
              ),
            ),
            especificacaoManual: yup
              .string()
              .when('especificacaoManual', val =>
                yupSchemaTextField(
                  val?.length > 0,
                  MIN_LENGTH_INPUT_TEXT,
                  MAX_LENGTH_INPUT_TEXT,
                ),
              ),
          },
          [['especificacaoManual', 'especificacaoManual']],
        ),
      ),
    )
    .required(),
);
const DEFAULT_ESPECIFICACAO = {
  titulo: '',
  portador: false,
  grau: '',
  local: '',
  tempo: '',
  tipo: '',
};

const Questoes: React.FC = () => {
  const classes = useStyles();
  const matchesXs = useMediaQuery('(min-width: 465px)');
  const matchesSm = useMediaQuery('(min-width: 600px)');
  const { setCurrentPage } = usePage();
  const { questionario } = useQuestion();
  const {
    beneficiariosConfig,
    beneficiariosResponse,
    formDataResponse,
    lastQuestionAnswered,
    pastBenefitForm,
    setFormDataResponse,
    setLastQuestionAnswered,
  } = useFormData();

  const [indexQuestao, setIndexQuestao] = useState(0);

  const questoesLength = useMemo(() => questionario.length, [questionario]);
  const currentQuestao = useMemo(
    () => questionario[indexQuestao],
    [questionario, indexQuestao],
  );

  const pastValuesValidation = useMemo(() => {
    const pastValues: IPastFormValues = {};

    pastBenefitForm.beneficiarios?.forEach(({ idBeneficiario, perguntas }) => {
      pastValues[idBeneficiario] = {
        illness: perguntas[indexQuestao]?.bloqueado,
      };
    });
    return pastValues;
  }, [indexQuestao]);

  const defaultValues = useMemo(() => {
    const values: IFormValues = {};

    beneficiariosConfig.forEach(({ idBeneficiario }) => {
      values[idBeneficiario] = {
        bloqueado: pastValuesValidation[idBeneficiario]?.illness,
        hasDisease: null,
        especificacao: [DEFAULT_ESPECIFICACAO],
        especificacaoManual: '',
        especificacaoManualAlteracoes: '',
      };
    });
    return values;
  }, [beneficiariosConfig]);

  const dirtyValues = useMemo(() => {
    const values = { ...defaultValues };

    beneficiariosResponse.forEach(({ idBeneficiario, perguntas }) => {
      const pergunta = perguntas?.find(({ id }) => id === currentQuestao.id);

      values[idBeneficiario].bloqueado =
        pastValuesValidation[idBeneficiario]?.illness;
      values[idBeneficiario].hasDisease = pergunta?.possui?.toString() ?? null;
      values[idBeneficiario].especificacao = pergunta?.especificacao ?? [
        DEFAULT_ESPECIFICACAO,
      ];
      values[idBeneficiario].especificacaoManual =
        pergunta?.especificacaoManual ?? '';
    });

    return values;
  }, [defaultValues, beneficiariosResponse, currentQuestao]);

  const methods = useForm<IFormValues>({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset, formState } = methods;

  const nextQuestao = (): void => setIndexQuestao(prevState => prevState + 1);

  const prevQuestao = (): void => setIndexQuestao(prevState => prevState - 1);

  const saveAnswer = useCallback(
    (values: IFormValues) => {
      const formDataCopy = { ...formDataResponse };
      const { id, titulo, subtitulo, especificacaoDinamica } = currentQuestao;

      Object.keys(values).forEach(idBeneficiario => {
        const index = formDataCopy.beneficiarios.findIndex(
          b => b.idBeneficiario === idBeneficiario,
        );
        const beneficiario = formDataCopy.beneficiarios[index];
        const perguntas = beneficiario?.perguntas || [];
        const perguntaIndex = perguntas.findIndex(obj => obj.id === id);
        const choices = values[idBeneficiario];

        const answer = {
          id,
          bloqueado: pastValuesValidation[idBeneficiario]?.illness ?? false,
          titulo,
          subtitulo,
          possui: !!choices.hasDisease,
          especificacaoDinamica,
          especificacaoManual: choices.especificacaoManual,
          especificacaoManualAlteracoes: choices.especificacaoManualAlteracoes,
          especificacao: choices.especificacao.map(e =>
            choices.hasDisease
              ? e
              : {
                  ...DEFAULT_ESPECIFICACAO,
                  titulo: especificacaoDinamica ? '' : e.titulo,
                },
          ),
        };

        if (perguntaIndex > -1) {
          perguntas[perguntaIndex] = answer;
        } else {
          perguntas.push(answer);
        }
        beneficiario.perguntas = perguntas;
      });
      setFormDataResponse(formDataCopy);
    },
    [formDataResponse, currentQuestao],
  );

  const handlePrevQuestao = (): void => {
    if (indexQuestao === 0) {
      setCurrentPage(5);
      return;
    }

    prevQuestao();
  };

  const onSubmit: SubmitHandler<IFormValues> = values => {
    saveAnswer(values);

    if (indexQuestao > lastQuestionAnswered) {
      setLastQuestionAnswered(indexQuestao);
    }

    if (indexQuestao + 1 === questoesLength) {
      setCurrentPage(6);
      return;
    }
    nextQuestao();
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    reset(dirtyValues);
  }, [dirtyValues, reset]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [indexQuestao]);

  return (
    <Box sx={classes.page}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={matchesXs ? classes.pageTitle : classes.pageTitleXs}>
            <Typography
              variant="h3"
              align="center"
              sx={matchesXs ? undefined : classes.titleXs}
            >
              {currentQuestao.titulo}
            </Typography>
            <Typography
              variant="h6"
              align="center"
              sx={matchesXs ? classes.subTitle : classes.subTitleXs}
            >
              {currentQuestao.subtitulo}
            </Typography>
          </Box>

          <Box sx={matchesXs ? classes.wrapper : classes.wrapperXs}>
            {beneficiariosConfig.map(beneficiario => (
              <Box
                key={beneficiario.idBeneficiario}
                sx={
                  matchesSm
                    ? classes.questionAccordion
                    : classes.questionAccordionSm
                }
              >
                <QuestionAccordion
                  beneficiario={beneficiario}
                  disease={currentQuestao}
                  validation={
                    pastValuesValidation[beneficiario.idBeneficiario]?.illness
                  }
                />
              </Box>
            ))}
          </Box>

          <Box sx={matchesSm ? classes.nav : classes.navSm}>
            <Button
              fullWidth
              variant="outlined"
              sx={classes.button}
              startIcon={<Icon>arrow_back_ios_icon</Icon>}
              onClick={handlePrevQuestao}
            >
              Voltar
            </Button>

            <Typography
              variant="h6"
              align="center"
              sx={matchesSm ? undefined : classes.pageNumber}
            >
              {`${indexQuestao + 1}/${questoesLength}`}
            </Typography>

            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={classes.fowardButton}
              endIcon={<Icon>arrow_forward_ios_icon</Icon>}
              color="primary"
              disabled={!formState.isValid}
            >
              Avançar
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default Questoes;
