import React, { useEffect } from 'react';
import toast from 'react-hot-toast';

import { Container, useMediaQuery } from '@mui/material';

import { Header } from 'components';

import { useAuth, usePage, useTheme } from 'hooks';

import {
  AditivoCPT,
  CartaOrientacaoBeneficiario,
  Confirmacao,
  DocumentacaoComp,
  ErroExpiradoOuPreenchido,
  ErroValidaLink,
  FormularioIdentificado,
  IMC,
  InfosImportantes,
  Questoes,
  Resumo,
  Tutorial,
  ValidacaoCPF,
} from 'pages';

import { api } from 'services';

const useStyles = (): any => ({
  container: {
    marginTop: 10,
    padding: 2,
  },
  containerXs: {
    padding: 2,
  },
});

const RoutesPreenchimento: React.FC = () => {
  const { currentPage, setCurrentPage } = usePage();
  const { isAuthenticated, session, setSession } = useAuth();
  const { isLoaded, setLayout, setShowLoading } = useTheme();
  const classes = useStyles();
  const matchesXs = useMediaQuery('(min-width: 465px)');

  const linkValidation = async (): Promise<void> => {
    const url = 'preenchimento-formulario-online/valida-link';
    const body = { link: window.location.pathname.replace('/', '') };

    setShowLoading(true);

    await api
      .post(url, body)
      .then(({ data }) => {
        const { status, operadora, protocolo } = data;

        if (status !== 'Pendente') {
          setCurrentPage(-1);
          toast.error(`Status: ${status}`);
          return;
        }

        setSession(prevState => ({
          ...prevState,
          insuranceHealth: operadora,
          protocol: protocolo,
        }));
        setCurrentPage(0);
      })
      .catch(err => {
        if (!err.response) {
          toast.error(
            'Problemas ao conectar no servidor! Tente novamente mais tarde.',
            { duration: 15000 },
          );
          return;
        }
        if (err.response.status === 404) {
          toast.error('Link não encontrado!');
        }

        setCurrentPage(-2); // Redirect to error page
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const loadAssets = async (protocolo: string): Promise<void> => {
    setShowLoading(true);

    await api
      .get(`parametrizacao-layout/protocolo/${protocolo}`)
      .then(({ data }) => {
        setLayout(data.layout);
      })
      .catch(() => {
        toast.error('Não foi possível carregar os assets');
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    linkValidation();
  }, []);

  useEffect(() => {
    const { protocol } = session;

    if (protocol) {
      loadAssets(protocol);
    }
  }, [session.protocol]);

  return (
    <>
      {isLoaded && <Header />}
      <Container sx={matchesXs ? classes.container : classes.containerXs}>
        {currentPage === -3 && isAuthenticated && <FormularioIdentificado />}
        {currentPage === -2 && !isAuthenticated && <ErroValidaLink />}
        {currentPage === -1 && !isAuthenticated && <ErroExpiradoOuPreenchido />}
        {currentPage === 0 && isLoaded && !isAuthenticated && <ValidacaoCPF />}
        {currentPage === 1 && isAuthenticated && (
          <CartaOrientacaoBeneficiario />
        )}
        {currentPage === 2 && isAuthenticated && <Tutorial />}
        {currentPage === 3 && isAuthenticated && <InfosImportantes />}
        {currentPage === 4 && isAuthenticated && <IMC />}
        {currentPage === 5 && isAuthenticated && <Questoes />}
        {currentPage === 6 && isAuthenticated && <DocumentacaoComp />}
        {currentPage === 7 && isAuthenticated && <Resumo />}
        {currentPage === 8 && isAuthenticated && <AditivoCPT />}
        {currentPage === 9 && isAuthenticated && <Confirmacao />}
      </Container>
    </>
  );
};

export default RoutesPreenchimento;
