import React, { useEffect, useState } from 'react';
import Lottie, { Options } from 'react-lottie';

import { Box, Grid, Typography, useMediaQuery } from '@mui/material';

import { useAuth, useFormData, useProtocol, usePage } from 'hooks';

import { api } from 'services';
import { token } from 'utils';

import CheckAnimation from 'assets/lotties/check_animation.json';
import ErrorAnimation from 'assets/lotties/error_animation.json';
import prettyDate from 'utils/prettyDate';
import toast from 'react-hot-toast';

const useStyles = (): any => ({
  page: {
    paddingTop: 5,
  },
  root: {
    marginTop: '-40px',
  },
  title: {
    fontSize: '2.5rem',
  },
  titleXs: {
    marginTop: '35px',
    fontSize: '1.7rem',
  },
  svgImage: {
    width: 300,
    marginTop: 10,
  },
  svgImageXs: {
    width: 200,
    marginTop: 25,
  },
});

const defaultLottieOptions: Options = {
  loop: false,
  autoplay: true,
  animationData: CheckAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const errorLottieOptions: Options = {
  loop: false,
  autoplay: true,
  animationData: ErrorAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Confirmacao: React.FC = () => {
  const classes = useStyles();
  const matchesXs = useMediaQuery('(min-width: 465px)');
  const { setStep } = usePage();
  const protocolo = useProtocol();
  const { formDataResponse } = useFormData();
  const { session } = useAuth();
  const [requestStatus, setRequestStatus] = useState('PENDING');

  const sendForm = (): void => {
    const headers = { Authorization: `Bearer ${token.getAccessToken()}` };
    const body = {
      protocolo,
      formulario: formDataResponse,
      sessao: session.id,
    };

    api
      .post('formulario', body, { headers })
      .then(() => {
        setRequestStatus('SUCCESS');
        toast.success('Declaração efetuada com sucesso!');
      })
      .catch(() => {
        setRequestStatus('ERROR');
        toast.error('Erro ao enviar a declaração!');
      });
  };

  useEffect(() => {
    setStep(3);
    sendForm();
  }, []);

  return (
    <Box sx={classes.page}>
      {requestStatus === 'ERROR' && (
        <Typography
          align="center"
          sx={matchesXs ? classes.title : classes.titleXs}
        >
          Sua Declaração não foi enviada!
        </Typography>
      )}
      {requestStatus === 'PENDING' && (
        <Typography
          variant="h3"
          align="center"
          sx={matchesXs ? undefined : classes.titleXs}
        >
          Sua Declaração está sendo enviada!
        </Typography>
      )}
      {requestStatus === 'SUCCESS' && (
        <Typography
          variant="h3"
          align="center"
          sx={matchesXs ? undefined : classes.titleXs}
        >
          Sua Declaração foi enviada com sucesso!
        </Typography>
      )}
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="center"
          direction="column"
          sx={classes.root}
        >
          {requestStatus === 'SUCCESS' && (
            <>
              <Box sx={matchesXs ? classes.svgImage : classes.svgImageXs}>
                <Lottie options={defaultLottieOptions} />
              </Box>

              <Typography gutterBottom variant="subtitle1">
                Data do envio: {prettyDate()}
              </Typography>
            </>
          )}
          {requestStatus === 'ERROR' && (
            <Box sx={matchesXs ? classes.svgImage : classes.svgImageXs}>
              <Lottie options={errorLottieOptions} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Confirmacao;
