import Keycloak from 'keycloak-js';

const config: Keycloak.KeycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL as string,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
  realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
};

const keycloak = Keycloak(config);

export default keycloak;
