import axios, { AxiosRequestConfig } from 'axios';
import toast from 'react-hot-toast';

import { token } from 'utils';

type ResponseType = {
  // eslint-disable-next-line camelcase
  access_token: string;
  // eslint-disable-next-line camelcase
  refresh_token: string;
};

const api = axios.create({ baseURL: process.env.REACT_APP_MS_AGENDA_URL });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchRefreshToken = async (error: any): Promise<unknown> =>
  new Promise(
    // eslint-disable-next-line consistent-return
    (resolve, reject) => {
      const accessToken = token.getAccessToken();
      const refreshToken = token.getRefreshToken();
      const body = { accessToken, refreshToken };

      return api
        .post('/clients-registrations', body)
        .then(async response => {
          token.setAccessToken(response.data.access_token);
          token.setRefreshToken(response.data.refresh_token);
          return resolve(response);
        })
        .catch(() => reject(error));
    },
  );

api.interceptors.response.use(
  response => response,
  async error => {
    const config = error.config as AxiosRequestConfig;
    const accessToken = token.getAccessToken();

    if (error?.response?.status === 401 && accessToken) {
      try {
        const response = (await fetchRefreshToken(error)) as {
          data: ResponseType;
        };
        config.headers.Authorization = `Bearer ${response.data.access_token}`;

        return new Promise(resolve => resolve(api(error.config)));
      } catch (err) {
        toast.error(
          'Falha ao obter autenticação com o servidor! Tente novamente mais tarde.',
          { duration: 20000 },
        );
      }
    }
    if (error?.response?.status === 403) {
      toast.error('Forbidden');
    }
    return Promise.reject(error);
  },
);

export default api;
